<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span v-for="(item, index) in tabs" :key="index" :class="{ title: tabIndex === index }" @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="content">
      <!-- 药品管理 -->
      <Drugs v-if="tabIndex === 0"></Drugs>
      <!-- 饮食管理 -->
      <Diet v-else-if="tabIndex === 1"></Diet>
      <!-- 运动管理 -->
      <Motion v-else-if="tabIndex === 2"></Motion>
      <!-- 健康评估管理 -->
      <Health v-else-if="tabIndex === 3"></Health>
      <!-- 干预方案管理 -->
      <Intervene v-else-if="tabIndex === 4"></Intervene>
      <!-- 综合干预管理 -->
      <Combine v-else-if="tabIndex === 5"></Combine>
      <!-- 中草药管理 -->
      <InDrug v-else-if="tabIndex === 6"></InDrug>
    </div>
  </div>
</template>

<script>
import Drugs from './drugs/drugs.vue'
import Diet from './diet/diet.vue'
import Motion from './motion/motion.vue'
import Health from './health/health.vue'
import Intervene from './intervene/intervene.vue'
import Combine from './combine/combine.vue'
import InDrug from './inDrug/inDrug.vue'
export default {
  components: {
    Drugs,
    Diet,
    Motion,
    Health,
    Intervene,
    Combine,
    InDrug
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['药品管理', '饮食管理', '运动管理', '健康评估管理', '干预方案管理', '综合干预管理', '中草药管理'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      // const urls = ['drugs', 'diet', 'motion', 'health', 'intervene', 'combine', 'indrug']
      // this.$router.push('/organrun/knowledgebase/' + urls[index])
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  width: calc(100% - .125rem);
  height: calc(100% - .125rem);
  padding: .25rem;
  border: .05rem solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: .75rem;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;

    span {
      height: .475rem;
      line-height: .475rem;
      margin-right: .2rem;
      padding: 0 .15rem;
      text-align: center;
      color: #fff;
      font-size: .275rem;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1rem);
    margin-top: .25rem;
  }
}
</style>
