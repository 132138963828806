<template>
  <div class="drugs">
    <div class="info-title">
      <el-form>
        <div class="info-box">
          <div class="item">
            <span class="key">发布日期</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
            <el-date-picker class="margin-left" type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item item1">
            <span>输入关键字</span>
            <el-input class="el-input-width" prefix-icon="el-icon-search" placeholder="请输入关键字"></el-input>
          </div>
          <el-button @click="openPublish">发布药品信息</el-button>
        </div>
      </el-form>
    </div>
    <div class="table">
      <el-table :data="tableData" height="340" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="42"></el-table-column>
        <el-table-column prop="name" label="编号"></el-table-column>
        <el-table-column label="列表图">
          <template>
            <span class="el-icon-picture"></span>
          </template>
        </el-table-column>
        <el-table-column label="药品标题"></el-table-column>
        <el-table-column label="药品分类"></el-table-column>
        <el-table-column label="发布人"></el-table-column>
        <el-table-column label="发布状态"></el-table-column>
        <el-table-column label="收藏量"></el-table-column>
        <el-table-column label="操作时间"></el-table-column>
        <el-table-column label="操作" width="103">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openUpdate">编辑</span>
              <span>删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <!-- 发布药品 -->
    <Publish v-if="showPublish" @close="closePublish"></Publish>
    <!-- 编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Publish from './publish.vue'
import Update from './update.vue'
export default {
  components: {
    Publish,
    Update
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }],
      showPublish: false,
      showUpdate: false
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openPublish () {
      this.showPublish = true
    },
    closePublish () {
      this.showPublish = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
.drugs {
  position: relative;
  width: 100%;
  height: 100%;

  .info-title {
    width: 100%;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: .25rem;
    font-weight: bold;
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 .125rem;
    padding-bottom: 0;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: .25rem;
      color: #01EEFD;
      font-size: .225rem;

      span {
        margin-right: .125rem;
      }

      .key {
        width: 1rem;
        text-align-last: justify;
      }

      .el-input {
        width: 2.125rem;
        height: .475rem;
      }

      .el-input-width {
        width: 3.1rem;
      }

      .margin-left {
        margin-left: .25rem;
      }
    }

    .el-button {
      background-color: #01EEFD;
      color: #fff;
      border: none;
    }
  }

  .table {
    width: 99%;
    margin-top: .125rem;
    border: .05rem #073F73 solid;
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // 整个滚动条
      width: 0rem; // 纵向滚动条的宽度
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: .2125rem;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: .2rem;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: .025rem solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: .0125rem solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: .0125rem solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: .0125rem solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0rem;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-checkbox__inner {
    background-color: transparent;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: .2rem;

    span {
      color: #0077F9;
      cursor: pointer;
    }

    .red {
      color: #FF0000;
    }

    //按钮文字显示
    .el-switch {
      height: .3125rem !important;
    }

    ::v-deep .el-switch__core {
      width: .675rem !important;
      height: .3rem;
      border-radius: 1.25rem;
      border: none;
    }

    ::v-deep .el-switch__core::after {
      width: .25rem;
      height: .25rem;
      top: .025rem;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -0.2625rem;
    }

    /*关闭时文字位置设置*/
    ::v-deep .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: .075rem;
      margin-left: 0rem;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: .15rem;
      }
    }

    /* 激活时另一个文字消失 */
    ::v-deep .el-switch__label.is-active {
      display: none;
    }

    /*开启时文字位置设置*/
    ::v-deep .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: .0625rem;
      margin-right: 0rem;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: .15rem;
      }
    }

  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .125rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
