<template>
  <div class="consult">
    <div class="consult-tab">
      <span>咨询分类</span>
      <span class="blue"></span>
      <span class="span">行业动态</span>
      <span class="item" v-for="(item, index) in tabs1" :key="index">{{ item }}</span>
    </div>
    <div class="consult-tab">
      <span>咨询分类</span>
      <span class="blue"></span>
      <span class="span">默认排序</span>
      <span class="item" v-for="(item, index) in tabs2" :key="index">{{ item }}</span>
      <div class="count">
        <span>共有</span>
        <span class="red">3</span>
        <span>篇咨询</span>
      </div>
      <el-button @click="openPublish">发布咨询</el-button>
    </div>
    <div class="scroll">
      <div class="cell" v-for="(item, index) in 20" :key="index">
        <div class="news">
          <div class="left">
            <div class="index">14</div>
            <div class="info">
              <div class="line">糖尿病患者如何科学运动？</div>
              <div class="line1">
                <span>作者：张三</span>
                <span>2017-08-03  10:16:23</span>
                <span>分类：糖尿病</span>
              </div>
              <div class="line2">查看全文</div>
              <div class="line3">
                <span>阅读</span>
                <span class="red">（2)</span>
              </div>
            </div>
          </div>
          <div class="right">
              <el-button @click="openUpdate">修改</el-button>
              <el-button class="blue">删除</el-button>
            </div>
        </div>
        <div class="border"></div>
      </div>
    </div>
    <!-- 修改 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 发布 -->
    <Publish v-if="showPublish" @close="closePublish"></Publish>
  </div>
</template>

<script>
import Update from './update.vue'
import Publish from './publish.vue'
export default {
  components: {
    Update,
    Publish
  },
  data () {
    return {
      tabs1: Object.freeze(['高血压', '糖尿病', '脑卒中', '冠心病', '慢阻肺', '肿瘤癌症']),
      tabs2: Object.freeze(['最多阅读', '最新发布', '咨询名称']),
      showUpdate: false,
      showPublish: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openPublish () {
      this.showPublish = true
    },
    closePublish () {
      this.showPublish = false
    }
  }
}
</script>

<style lang="less" scoped>
.consult {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 9px;
  box-sizing: border-box;

  .consult-tab {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #FF0000;
    font-size: 20px;

    .blue {
      width: 1px;
      height: 24px;
      margin-left: 29px;
      background-color: #01EEFD;
    }

    .span {
      margin-left: 29px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    .item {
      width: 68px;
      margin-left: 59px;
      color: #fff;
      font-size: 16px;
      white-space: nowrap;
    }

    .count {
      margin-left: 59px;
      color: #fff;
      font-size: 16px;

      .red {
        color: #FF0000;
      }
    }

    .el-button {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin-left: 43px;
      padding: 0;
      background-color: #FF0000;
      color: #fff;
      font-size: 16px;
      border: 0;
      border-radius: 0;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    padding-left: 32px;
    padding-right: 20px;
    border: 1px solid #01EEFD;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .cell {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 128px;
      padding-top: 21px;
      box-sizing: border-box;

      .news {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;
        padding-right: 69px;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .index {
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            border-radius: 50%;
            background-color: #01EEFD;
            color: #fff;
            font-size: 36px;
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 13px;

            .line {
              color: #fff;
              font-size: 16px;
            }

            .line1 {
              display: flex;
              justify-content: space-between;
              width: 282px;
              color: #fff;
              font-size: 12px;
            }

            .line2 {
              color: #01EEFD;
              font-size: 12px;
            }

            .line3 {
              color: #fff;
              font-size: 12px;

              .red {
                color: #FF0000;
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: space-between;
          width: 210px;
          height: 36px;

          .el-button {
            width: 70px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            padding: 0;
            border: 0;
            border-radius: 0;
            color: #fff;
            font-size: 16px;
            background-color: #4AB2EF;
          }

          .blue {
            background-color: #01EEFD;
          }
        }
      }

      .border {
        width: calc(100% - 37px);
        height: 1px;
        margin-left: 37px;
        background-color: #01EEFD;
      }
    }
  }
}
</style>
