<template>
  <div class="son-organ">
    <div class="son-search">
      <div class="son-item">
        <span class="key">机构名称</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <el-button @click="getList">搜索</el-button>
      <el-button>清空</el-button>
    </div>
    <el-table :data="tableData" height="400" style="width: 100%">
      <el-table-column type="index" label="序号" width="60">
      </el-table-column>
      <el-table-column prop="sjjgmc" label="上级机构名称">
      </el-table-column>
      <el-table-column prop="jgjb" label="机构级别">
      </el-table-column>
      <el-table-column prop="xzjdmc" label="机构名称">
      </el-table-column>
      <el-table-column prop="jgbh" label="机构编号">
      </el-table-column>
      <el-table-column label="平台机构名称">
        <template slot-scope="scope">
          <el-select v-model="scope.row.ptjgbh" placeholder="请选择">
            <el-option
              v-for="item in organs"
              :key="item.id"
              :label="item.jgmc"
              :value="item.jgid">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="jgbm" label="平台机构编号">
      </el-table-column>
      <el-table-column prop="dnrs" label="档案数">
      </el-table-column>
      <el-table-column prop="zfwrs" label="任务数">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.flag | statusType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240">
        <template>
          <!-- slot-scope="scope" -->
          <div class="flex">
            <span @click="openUpdate">编辑</span>
            <el-button size="mini" type="primary">保存</el-button>
            <span @click="openRun">管辖用户</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <!-- 编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 管辖用户 -->
    <Run v-if="showRun" @close="closeRun"></Run>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Update from './update.vue'
import Run from './run.vue'
export default {
  components: {
    Update,
    Run
  },
  data () {
    return {
      issynchronous: false,
      options: [],
      organs: [],
      value: '',
      tableData: [],
      total: 400,
      currentPage: 0,
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        start: '0',
        length: '10',
        jgbh: ''
      },
      showSynchronous: false,
      showUpdate: false,
      showRun: false
    }
  },
  created () {
    this.getList()
    this.getOrganInfo()
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  filters: {
    statusType (val) {
      if (val === '0') return '启动'
      if (val === '1') return '关闭'
      return '-'
    }
  },
  methods: {
    async getList () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-200676290
      try {
        this.params.jgbh = this.loginInfo.jgbh || ''
        const { data: res } = await this.$http.post('/api/jkct/findjglb', this.params)
        console.log('机构信息', res)
        if (res.code !== 0 || res.data === null) return
        this.tableData = res.data
      } catch (err) {
        console.log('机构信息', err)
      }
    },
    async getOrganInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-200762264
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || ''
        }
        const { data: res } = await this.$http.post('/api/jkct/findptjglb', params)
        console.log('平台机构信息', res)
        if (res.code !== 0 || res.data === null) return
        this.organs = res.data
      } catch (err) {
        console.log('平台机构信息', err)
      }
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openRun () {
      this.showRun = true
    },
    closeRun () {
      this.showRun = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.son-organ {
  width: 100%;
  height: 100%;

  .son-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-right: 30px;

      .key {
        width: 110px;
        color: #01EEFD;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }
    }

    .el-button {
      margin-top: 20px;
      margin-right: 40px;
      background-color: #04B4D0;
      border: none;
      color: #fff;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;

    .icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #04B4D0;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-table {
    background-color: #081C38;
    border: 4px solid #073F73;
    box-sizing: border-box;

    &::before {
      background-color: transparent;
    }

    // 表头首行
    /deep/th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    /deep/td.el-table__cell {
      border-bottom: 1px solid #01EEFD;
    }

    // 表头背景颜色
    /deep/th.el-table__cell {
      background-color: #024276;
    }

    /deep/.el-table__cell {
      text-align: center;
      color: #fff;
    }

    /deep/tr {
      background-color: #081C38;
    }

    /deep/.el-table__body tr:hover>td.el-table__cell {
      background-color: transparent !important;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.is-indeterminate .el-checkbox__inner,
    /deep/.is-checked .el-checkbox__inner {
      background-color: #04B4D0;
    }

    /deep/.el-table__body-wrapper::-webkit-scrollbar {
      width: 0;
    }

    /deep/.el-table__empty-text {
      color: #fff;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      color: #fff;

      span {
        cursor: pointer;
      }

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .green {
    color: #00FF88;
  }

  .organ {
    color: #FF6600;
  }

  .red {
    color: #FF0000;
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total,
    /deep/.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    /deep/.el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
