<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>新增</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-content">
            <div class="info-item">
              <span class="key">主题</span>
              <el-input v-model="params.theme" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">时间</span>
              <el-date-picker v-model="params.hdsj" type="date" value-format="yyyy-MM-dd" placeholder="选择日期时间">
              </el-date-picker>
            </div>
            <div class="info-item">
              <span class="key">地点</span>
              <el-input v-model="params.hddd" placeholder=""></el-input>
            </div>
            <div class="info-item">
              <span class="key">主持人</span>
              <el-input v-model="params.zcr" placeholder=""></el-input>
            </div>
            <div class="update-sign-right">
              <div class="update-sign-img" v-if="!params.imageUrl"></div>
              <img v-else :src="params.imageUrl" style="width: 50px; height: 50px"  class="update-sign-img">
              <input type="file" style="display: none" ref="fileInput" @change="onSelectImage" />
              <el-button size="mini" class="color1" @click="update">上传图片</el-button>
            </div>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="submit">保存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      options: [{
        value: '1',
        label: '男'
      },
      {
        value: '2',
        label: '女'
      }],
      params: {
        jgbh: '',
        province: '',
        name: '',
        sex: '',
        title: '',
        dept: '',
        imageUrl: '',
        fileData: '',
        url: '',
        zcr: '',
        hddd: '',
        hdsj: '',
        theme: '',
        introduction: ''
      },
      provinceId: '',
      proviceName: '',
      provinces: []
    }
  },
  created () {
    this.pchange()
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async submit () {
      try {
        if (this.params.jgbh == null || this.params.jgbh === '') {
          this.params.jgbh = this.$parent.params.jgbh
        }
        this.params.imageUrl = this.params.url
        console.log('地址' + this.params.imageUrl)
        console.log(this.params.province, '--------------')
        const { data: res } = await this.$http.post('/api/jkct/jkjyjzxz', this.params)
        console.log('医活动列表保存 ', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('活动列表保存 请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    onSelectImage (e) {
      var file = e.target.files[0]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      console.log(reader)
      reader.onload = res => {
        this.params.imageUrl = res.target.result
        this.uploadImg()
      }
    },
    update () {
      this.$refs.fileInput.click()
    },
    async uploadImg () {
      try {
        this.loading = true
        const obj = {
          qmtype: 'ysqm',
          username: this.$store.state.loginInfo.username || '',
          jgbh: this.$store.state.loginInfo.jgbh || '',
          openid: '',
          qmid: 1,
          unionid: '',
          fileData: this.params.imageUrl
        }
        console.log('-+++++++++++++-' + obj.fileData)
        const { data: res } = await this.$http.post('/files/uploadfile', obj)
        // console.log('签名信息上传', res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('保存成功')
        this.params.url = res.data.ocrtpurl
        console.log('imageurl' + res.data.ocrtpurl)
      } catch (error) {
        this.loading = false
        console.log('请求失败', error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1160px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 520px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 10px 30px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 50px);

        .info-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;
          margin: 10px 0;

          .key {
            width: 110px;
            color: #01EEFD;
            font-size: 18px;
          }

          .el-input {
            width: 200px;
          }

          .el-select {
            width: 200px;
          }
        }
      }

      .info-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;

        .el-button {
          margin: 0 60px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
