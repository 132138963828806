<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">用户基本信息</span>
    </div>
    <div class="son-content">
      <div class="son-item">
        <span class="key">用户名</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">密码</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">姓名</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">身份证号</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">手机号码</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">平台密码</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">机构级别</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">机构编号</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">机构名称</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">下属机构</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">所属分类</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">所属职责</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">总检审核</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">签名地址</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">责任医生</span>
        <el-input placeholder="请输入"></el-input>
      </div>
    </div>
    <div class="son-btns">
      <el-button>清空</el-button>
      <el-button class="son-blue">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      value: ''
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 22px;
    }

  }

  .son-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: start;
    width: 100%;
    height: calc(100% - 120px);
    padding-top: 20px;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      margin-bottom: 30px;

      .key {
        width: 110px;
        color: #01EEFD;
        font-size: 22px;
      }

      .key1 {
        width: 140px;
      }

      .el-input, .el-select {
        width: 200px;
      }
    }
  }

  .son-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .el-button {
      margin-left: 50px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .son-blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
