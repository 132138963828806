<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">系统配置</span>
    </div>
    <div class="son-content">
      <div class="son-head">
        <span>一、计算配置</span>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>1.智慧公卫平台-计算配置一血压修正，默认选择A ( )</span>
        </div>
        <el-radio-group class="son-line" v-model="radio">
          <el-radio label="1">A 双侧</el-radio>
          <el-radio label="2">B 奇偶</el-radio>
          <el-radio label="3">C 去零</el-radio>
        </el-radio-group>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>2.智慧公卫平台-计算配置一脉率和心率，默认选择心率，非特殊需求，一般不建议 脉率( )</span>
        </div>
        <el-radio-group class="son-line" v-model="radio">
          <el-radio label="1">心率</el-radio>
          <el-radio label="2">脉率</el-radio>
        </el-radio-group>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>3.智慧公卫平台计算配置一身高体重算法， 冠心公卫系统建议选择 ：【算法1】，选 择( )</span>
        </div>
        <el-radio-group class="son-line1" v-model="radio">
          <el-radio label="1">A算法1：默认标准算法，身高保留1位小数，体重保留1位小数，BMI保留两位小数，四舍五入。</el-radio>
          <el-radio label="2">C算法3：身高体重保留整数，四舍五入，BMI保留2位小数，四舍五入。</el-radio>
          <el-radio label="3">B算法2：身高保留1位小数，体重保留1位小数，BMI使用 身高体重的整数计算，保留两位小数，舍弃尾数。</el-radio>
          <el-radio label="4">D算法4：身高保留整数，四舍五入，体重不变，BMI保留两位小数，四舍五入。</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>4.智慧公卫平台计算配置一健康评价样式（健康评价异常1-4）,选择(  )</span>
        </div>
        <el-radio-group class="son-line1" v-model="radio">
          <el-radio label="1">A包含测量值：健康评价异常1-4,显示测量值，如：血
糖偏高:8.1mmol/L</el-radio>
          <el-radio label="2">B不包含测量值：健康评价异常1-4,不显示测量值，如：血
糖偏髙；</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>5.智慧公卫平台计算配置一减体重方法（危险控制因素5减体重使用），选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A减到标准体重</el-radio>
          <el-radio label="2">B标准体重差</el-radio>
          <el-radio label="3">C减1-2公斤</el-radio>
          <el-radio label="4">D减2-5公斤</el-radio>
          <el-radio label="5">E超重减至标准体重，肥胖减至3S公斤</el-radio>
          <el-radio label="6">F肥胖减2~3kg,偏瘦加2~3kg</el-radio>
          <el-radio label="7">G超重减3kg,肥胖减5kg</el-radio>
          <el-radio label="8">H超重BMK25减至标准体重，BMI>25减3~6kg</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>6.智慧公卫平台计算配置一减腰围样式（危险控制因素7其他），选择(  )</span>
        </div>
        <el-radio-group class="son-line1" v-model="radio">
          <el-radio label="1">A不包含值：危险控制因素7其他，不显示值，如：减腰围</el-radio>
          <el-radio label="2">B减1~3厘米：危险控制因素7其他，显示值，如：减腰围3cm</el-radio>
          <el-radio label="3">C减3-5厘米，目标值：危险控制因素7其他，显示目标
值，如：减腰围至84cm</el-radio>
          <el-radio label="4">D减2厘米，目标值：危险控制因素7其他，显示目标值，如：减腰围至85cm</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>7.智慧公卫平台计算配置一提示字（健康评价异常1-4），选择(  )</span>
        </div>
        <el-radio-group class="son-line" v-model="radio">
          <el-radio label="1">A偏高，偏低：健康评价异常1-4,显示指标偏髙偏低，如：血压偏高</el-radio>
          <el-radio label="2">B高，低：健康评价异常1-4,显示指标高低，如：血压高</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>8.智慧公卫平台计算配置一血压异常（添加到健康评价异常的形式），选择(  )</span>
        </div>
        <el-radio-group class="son-line1" v-model="radio">
          <el-radio label="1">A添加单侧到健康评价：健康评价异常1-4中显示，如：血压偏高:左196/106mmHg</el-radio>
          <el-radio label="2">B添加双侧到健康评价：健康评价异常I1-4中显示，如：血压偏高:左196/106mmHg 右194/104mmHg</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>9.智慧公卫平台计算配置一口腔和视力异常、听力异常、吸烟和喝酒、血压血糖控制、症 状（是否添加到健康评价异常），分别选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A不添加健康评价</el-radio>
          <el-radio label="2">B添加到健康评价</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>10.智慧公卫平台计算配置一计算腰围（是否计算腰围），选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A不计算：手工录入</el-radio>
          <el-radio label="2">B依据身高体重计算</el-radio>
          <el-radio label="3">C依据BMI计算</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>11.智慧公卫平台计算配置一现存健康问题（是否添加到健康评价异常），选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A不添加健康评价</el-radio>
          <el-radio label="2">B添加到健康评价［6种慢病：高血压、糖尿病、脑卒中、肺结核、冠心病、精神病】</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>12.智慧公卫平台计算配置一现存慢病（健康指导是否选择1纳入慢性病患者健康管 理），选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A不纳入慢病管理</el-radio>
          <el-radio label="2">B纳入慢病管理【6种慢病：高血压、糖尿病、脑卒中、肺结核、冠心病、精神病】</el-radio>
          <el-radio label="3">C仅高血压/糖尿病纳入</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>13.智慧公卫平台计算配置一心电图分析，选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A执行【执行，是计算的时候，会计算异常，会分析岀山东版的样式，心电图异常进健 康评价】</el-radio>
          <el-radio label="2">B不执行【不执行是心电计算，也不分析，也没异常】</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>14.【不做处理】智慧公卫平台计算配置一添加危险控制因素、添加建议接种疫苗、添 加危险控制因素其他（注：危险控制因素选择项：1-7,选择6, 7,填写内容）</span>
        </div>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>14.【不做处理】智慧公卫平台计算配置一添加危险控制因素、添加建议接种疫苗、添 加危险控制因素其他（注：危险控制因素选择项：1-7,选择6, 7,填写内容）</span>
        </div>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>15.【不做处理】智慧公卫平台计算配置一高血脂危险控制、高血压危险控制、高 血糖危险控制（注：填写危险控制因素其他的内容）</span>
        </div>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>16.血压、血糖参考范围值，血糖(    ,   ),血压（   ， ）</span>
        </div>
      </div>

      <div class="son-head">
        <span>二、车载数据审核软件，中医保健指导</span>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>1.医指导默认值，选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A 随机，随机数量</el-radio>
          <el-radio label="2">B 固定值</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>2.中医指导默认值选择固定值，固定值选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A 情志调摄</el-radio>
          <el-radio label="2">B 饮食调养</el-radio>
          <el-radio label="3">C 起居调摄</el-radio>
          <el-radio label="4">D 运动保健</el-radio>
          <el-radio label="5">E 穴位保健</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>3.平和质是否指导，选择(  )</span>
        </div>
        <el-radio-group class="son-line son-line2" v-model="radio">
          <el-radio label="1">A 是</el-radio>
          <el-radio label="2">B 否</el-radio>
        </el-radio-group>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>生化检验指标范围</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>血常规：</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>生化：</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>尿常规：</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>医生名字及签名</span>
          <div class="sign"></div>
        </div>
      </div>

      <div class="son-head">
        <span>三、打印体检报告和上传数据相关问题</span>
      </div>

      <div class="son-list">
        <div class="son-line">
          <span>1.体检前一定在问询系统上设置好医生，否则后续的医生处理很麻烦，每天的医生是否需要更换，和医院沟通清楚。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>2.设定反馈日期，默认体检日期推后</span>
          <el-input></el-input>
          <span>天。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>3.档案号和血型的问题，档案号和血型</span>
          <el-input placeholder="是/否"></el-input>
          <span>显示到体检报告中。如果需要显示，需要 将公卫系统中导出信息。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>4.血常规和生化责任医生签名问题，血常规和生化送往第三方检验中心检验，签名医生需 要配置乡镇卫生院医生。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>5.体检完成后，是否需要在体检平台上补充信息，需要在平台上给补录信息的医生开通账 号，建议集中培训，集中补录，技术实施人员现场指导，确认好截止日期。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>6.体检数据审核功能，数据上传到体检平台，是否需要乡镇卫生院医生或村医登陆体检平台审核，完成审核后，数据再上传公卫系统和打印体检报告。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>7.确认补录截止日期、上传日期、打印体检报告日期。</span>
        </div>
      </div>
      <div class="son-list">
        <div class="son-line">
          <span>8.打印反馈报告和体检报告模板，目前的报告模板是否有改动，改动的地方。</span>
        </div>
      </div>
    </div>
    <div class="son-btns">
      <el-button class="son-blue">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1'
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 22px;
    }

  }

  .son-content {
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: start;
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 20px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-head {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #04B4D0;
      box-sizing: border-box;
      color: #fff;
      font-size: 22px;
    }

    .son-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;

      .son-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
        color: #fff;
        font-size: 20px;

        .sign {
          width: 208px;
          height: 50px;
          border-bottom: 1px solid #fff;
        }

        .el-input {
          width: 87px;
          /deep/.el-input__inner {
            height: 40px;
            line-height: 40px;
            background-color: transparent;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #fff;
            color: #fff;
          }
        }
      }

      .son-line1 {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        color: #fff;
        font-size: 20px;

        .el-radio {
          margin-bottom: 20px;
        }

        .el-radio:last-child {
          margin-bottom: 0;
        }
      }

      .son-line2 {
        flex-wrap: wrap;
        margin-bottom: 0;

        .el-radio {
          margin-bottom: 20px;
        }
      }

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }
    }
  }

  .son-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .el-button {
      margin-left: 50px;
      background-color: #33465C;
      border: none;
      color: #fff;
    }

    .son-blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
