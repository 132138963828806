<template>
  <div class="children-page">
    <SonHead></SonHead>
    <div class="children-box">
      <div class="tab-box">
        <div class="add">
          <div class="left">
            <el-button type="primary">
              <span class="el-icon-plus"></span>
              <span>新增</span>
            </el-button>
            <!-- <el-button type="primary">
              <span class="el-icon-info"></span>
              <span>同步机构数据</span>
            </el-button>
            <el-button type="primary" plain>下属机构：79 个</el-button> -->
          </div>
          <div class="right">
            <el-button icon="el-icon-refresh" circle></el-button>
            <el-button icon="el-icon-menu" circle></el-button>
            <!-- <el-button icon="el-icon-search" circle></el-button> -->
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border>
            <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')" fixed="left">
            </el-table-column>
            <el-table-column prop="name" label="app名称" :width="flexWidth('name', tableData, 'app名称')"></el-table-column>
            <el-table-column prop="sfzh" label="app版本" :width="flexWidth('sfzh', tableData, 'app版本')"></el-table-column>
            <el-table-column prop="age" label="app更新url" :width="flexWidth('name', tableData, 'app更新url')"></el-table-column>
            <el-table-column prop="name" label="强制更新" :width="flexWidth('name', tableData, '强制更新')"></el-table-column>
            <!-- <el-table-column prop="name" label="更新描述" :width="flexWidth('name', tableData, '更新描述')"></el-table-column> -->
            <el-table-column prop="name" label="更新描述" width="350"></el-table-column>
            <!-- <el-table-column prop="name" label="服务器标志" :width="flexWidth('name', tableData, '服务器标志')"></el-table-column> -->
            <el-table-column prop="name" label="服务器标志" width="250"></el-table-column>
            <!-- <el-table-column prop="name" label="服务器版本" :width="flexWidth('name', tableData, '服务器版本')"></el-table-column> -->
            <el-table-column prop="name" label="服务器版本" width="250"></el-table-column>
            <el-table-column prop="name" label="备注" :width="flexWidth('name', tableData, '备注')"></el-table-column>
            <el-table-column label="操作" width="140" fixed="right">
              <!-- slot-scope="scope" -->
              <template>
                <div class="flex">
                  <el-button type="primary" size="small">删除</el-button>
                  <el-button type="primary" size="small">编辑</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="query1.pageNum" background :page-size="query1.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="query1.total" hide-on-single-page>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const SonHead = () => import('../../../../components/son-head/son-head.vue')
export default {
  components: {
    SonHead
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      activeIndex: '0',
      params: {
        jgbh: '',
        cwhmcid: ''
      },
      tableData: [{}],
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 100
      }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    organs () {
      if (this.loginInfo.jgalllist === null && this.loginInfo.jgalllist.length > 0) return []
      return this.loginInfo.jgalllist
    }
  },
  methods: {
    selectChange (e) {
      console.log(e)
    },
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  box-sizing: border-box;

  .children-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .children-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .children-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .children-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .children-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .children-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 40px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }
    }
  }

  .children-box {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 20px;
    background-color: #fff;
    box-sizing: border-box;

    /deep/.el-menu-item {
      font-size: 16px;
    }

    .tab-box {
      width: 100%;
      height: calc(100% - 80px);
      margin-top: 10px;

      .input {
        display: flex;
        align-items: center;
        align-content: space-evenly;
        flex-wrap: wrap;
        height: 170px;
        box-sizing: border-box;

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          margin-right: 20px;

          .key {
            margin-right: 10px;
            white-space: nowrap;
            // color: #01EEFD;
            color: #000;
            font-size: 16px;
          }

          .margin {
            margin-left: 10px;
          }

          .el-select {
            width: 250px;
          }

          /deep/.el-checkbox__label {
            color: #F2F2F2;
            font-size: 18px;
          }

          .green {
            margin: 0;
            margin-right: 20px;
            text-align: center;
            background-color: #04B4D0;
            color: #fff;
            font-size: 18px;
          }
        }

        /deep/.el-radio__inner {
          background-color: transparent;
        }

        /deep/.el-radio__label {
          color: #fff;
          font-size: 14px;
        }

        .search {
          width: 300px;
        }
      }

      .add {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 60px;

        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .right {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .table {
        width: 100%;
        height: calc(100% - 80px);

        /deep/.el-table .cell {
          text-align: center;
          color: #000;
          font-size: 16px;
        }

        .flex {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
        }
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;
      }
    }
  }

}

.children-page1 {
  position: relative;
}
</style>
