<template>
  <div class="info">
    <div class="scroll">
      <el-form>
        <div class="info-title">
          <span>基本信息</span>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">药品名称</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">药品分类</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">药品规格</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">药品图片</span>
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span>点击上传</span>
            </el-upload>
          </div>
        </div>
        <div class="info-box">
          <div class="item item1">
            <span class="key">药品详情</span>
            <el-input type="textarea" placeholder="请输入内容">
            </el-input>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span>发布时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">排序号</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btns">
      <el-button class="color">发布</el-button>
      <el-button @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imageUrl: '',
      value: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #08223D;

  .info-title {
    width: 100%;
    height: .5rem;
    line-height: .5rem;
    padding-left: .225rem;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: .225rem;
    font-weight: bold;
  }

  .scroll {
    width: 100%;
    height: calc(100% - .625rem);
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: .25rem;
    padding-bottom: 0;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      color: #01EEFD;
      font-size: .225rem;

      span {
        margin-right: .125rem;
        white-space: nowrap;
      }

      .key {
        width: 1rem;
        text-align-last: justify;
      }

      .el-input {
        width: 2.125rem;
        height: .475rem;
      }

      .el-input-width {
        width: 3.1rem;
      }

      .el-button {
        background-color: #01EEFD;
        color: #fff;
        border: none;
      }

      .el-textarea {
        height: 1.4125rem;

        /deep/.el-textarea__inner {
          height: 100%;
          resize: none;
        }
      }
    }

    .item1 {
      width: 95%;
    }
  }

  /deep/.avatar-uploader .el-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    width: 3.2625rem;
    height: 1.4875rem;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #01EEFD;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;
    padding-left: .4125rem;
    box-sizing: border-box;

    .el-button {
      width: .875rem;
      height: .325rem;
      line-height: .325rem;
      margin-right: .625rem;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: .175rem;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
