<template>
  <div class="page">
    <div class="title">
      <span>修改</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <avue-ueditor v-model="text" v-bind="options"></avue-ueditor>
      </div>
      <div class="btns">
        <el-button @click="close">修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text: '',
      options: {
        // 普通上传地址
        action: '',
        customConfig: {}, // wangEditor编辑的配置
        // 是否启用oss(qiniu/ali)
        oss: '',
        headers: {},
        data: {},
        propsHttp: {
          home: '',
          url: 'url',
          res: 'data'
        },
        // 七牛云oss配置
        qiniu: {
          AK: '',
          SK: '',
          scope: '',
          url: '',
          deadline: 1
        },
        // 阿里云oss配置
        ali: {
          region: '',
          endpoint: '',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: ''
        }
      }
    }
  },
  watch: {
    text (news) {
      // console.log(news)
    }
  },
  mounted () { },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #08223D;

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 10rem;
    height: calc(100% - .7875rem);
    margin-top: .125rem;
    padding: .625rem .8125rem;
    padding-top: .875rem;
    background-image: url('../../../img/dialogContentBg.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .box {
      width: 100%;
      height: calc(100% - .75rem);
    }

    .close {
      position: absolute;
      top: .375rem;
      right: .4rem;
      color: #fff;
      font-size: .425rem;
      cursor: pointer;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: .5rem;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        text-align: center;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        color: #fff;
        font-size: .175rem;
        border: 0;
        padding: 0;
        border-radius: 0;
      }
    }
  }
}
</style>
