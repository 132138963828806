<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">机构用户维护</span>
    </div>
    <div class="son-search">
      <div class="son-item">
        <span class="key">姓名</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">平台密码</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">手机号</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <div class="son-item">
        <span class="key">机构级别</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">机构名称</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-item">
        <span class="key">平台验证</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button>搜索</el-button>
      <el-button>清空</el-button>
    </div>
    <div class="add">
      <div class="icon" @click="openUpdate">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <el-table :data="tableData" height="400" style="width: 100%">
      <el-table-column type="index" label="序号" width="70">
      </el-table-column>
      <el-table-column prop="name" label="用户名">
      </el-table-column>
      <el-table-column prop="date" label="姓名">
      </el-table-column>
      <el-table-column prop="date1" label="平台密钥">
      </el-table-column>
      <el-table-column prop="date1" label="手机号">
      </el-table-column>
      <el-table-column prop="date1" label="机构名称">
      </el-table-column>
      <el-table-column prop="date1" label="平台用户名">
      </el-table-column>
      <el-table-column prop="date1" label="平台密码">
      </el-table-column>
      <el-table-column label="平台验证" width="100">
        <template slot-scope="scope">
          <div class="flex" v-if="scope.row.show">
            <span @click="closeIssynchronous(scope.row)">取消</span>
            <span @click="closeIssynchronous(scope.row)">保存</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否绑定">
        <template slot-scope="scope">
          <div class="green" v-if="scope.row.status === 1">
            <span>是</span>
          </div>
          <div class="red" v-else>
            <span>否</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <div class="green">
          <span>启用</span>
        </div>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <div class="flex">
            <span @click="openIssynchronous(scope.row)">同步</span>
            <span @click="openUpdate">编辑</span>
            <span @click="openIssynchronous">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <!-- 同步 -->
    <Synchronous v-if="showSynchronous" @close="clsoeSynchronous"></Synchronous>
    <!-- 编辑 新增 更新 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import Synchronous from './synchronous.vue'
import Update from './update.vue'
export default {
  components: {
    Synchronous,
    Update
  },
  data () {
    return {
      issynchronous: false,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      tableData: [],
      total: 400,
      currentPage: 0,
      showSynchronous: false,
      showUpdate: false
    }
  },
  methods: {
    openIssynchronous (obj) {
      if (obj.status === 1) {
        obj.show = true
      } else {
        this.openSynchronous()
      }
    },
    closeIssynchronous (obj) {
      obj.show = false
    },
    openSynchronous () {
      this.showSynchronous = true
    },
    clsoeSynchronous () {
      this.showSynchronous = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      padding: 0 9px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 22px;
    }

  }

  .son-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-right: 30px;

      .key {
        width: 110px;
        color: #01EEFD;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }
    }

    .el-button {
      margin-top: 20px;
      margin-right: 40px;
      background-color: #04B4D0;
      border: none;
      color: #fff;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;

    .icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #04B4D0;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-table {
    background-color: #081C38;
    border: 4px solid #073F73;
    box-sizing: border-box;

    &::before {
      background-color: transparent;
    }

    // 表头首行
    /deep/th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    /deep/td.el-table__cell {
      border-bottom: 1px solid #01EEFD;
    }

    // 表头背景颜色
    /deep/th.el-table__cell {
      background-color: #024276;
    }

    /deep/.el-table__cell {
      text-align: center;
      color: #fff;
    }

    /deep/tr {
      background-color: #081C38;
    }

    /deep/.el-table__body tr:hover>td.el-table__cell {
      background-color: transparent !important;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.is-indeterminate .el-checkbox__inner,
    /deep/.is-checked .el-checkbox__inner {
      background-color: #04B4D0;
    }

    /deep/.el-table__body-wrapper::-webkit-scrollbar {
      width: 0;
    }

    /deep/.el-table__empty-text {
      color: #fff;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      color: #fff;

      span {
        cursor: pointer;
      }

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .green {
    color: #00FF88;
  }

  .organ {
    color: #FF6600;
  }

  .red {
    color: #FF0000;
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total,
    /deep/.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    /deep/.el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
