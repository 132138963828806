<template>
  <div class="children-page">
    <SonHead></SonHead>
    <div class="children-box">
      <div class="tab-box">
        <div class="add">
          <el-button type="primary">新增</el-button>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" border lazy row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column prop="name" label="名称">
            </el-table-column>
            <el-table-column prop="id" label="类型">
              <el-tag type="success">菜单</el-tag>
            </el-table-column>
            <el-table-column prop="href" label="菜单URL">
            </el-table-column>
            <el-table-column prop="permission" label="授权标识">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <div class="flex">
                <span class="span">编辑</span>
                <span class="span">删除</span>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const SonHead = () => import('../../../../components/son-head/son-head.vue')
export default {
  components: {
    SonHead
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      activeIndex: '0',
      params: {
        jgbh: '',
        cwhmcid: ''
      },
      tableData: [{
        id: 1,
        name: '用户管理',
        children: [{
          id: 1111,
          name: '新增',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 1112,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 2,
        name: '修改密码',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 3,
        name: '系统设置',
        children: [{
          id: 31,
          name: '菜单',
          href: '',
          permission: '',
          sort: '',
          children: [{
            id: 311,
            name: '查询',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 312,
            name: '新增',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 313,
            name: '删除',
            href: '',
            permission: '',
            sort: ''
          }]
        }, {
          id: 32,
          name: '角色',
          href: '',
          permission: '',
          sort: '',
          children: [{
            id: 321,
            name: '查询',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 322,
            name: '新增',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 323,
            name: '删除',
            href: '',
            permission: '',
            sort: ''
          }]
        }]
      }, {
        id: 4,
        name: '文件管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 41,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 42,
          name: '删除',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 5,
        name: '数据源监控',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 6,
        name: '接口swagger',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 7,
        name: '代码生成',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 8,
        name: '公告管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 81,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 82,
          name: '添加',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 83,
          name: '删除',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 9,
        name: '日志查询',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 10,
        name: '邮件管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 101,
          name: '发送邮件',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 102,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 11,
        name: '定时任务管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 111,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 112,
          name: '新增',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 113,
          name: '删除',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 12,
        name: 'excel导出',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 121,
          name: '导出',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 122,
          name: '页面显示数据',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 13,
        name: '字典管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 131,
          name: '查询',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 132,
          name: '新增',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 133,
          name: '删除',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 14,
        name: 'app版本控制',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 16,
        name: '正凯医疗智慧服务平台',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 161,
          name: '公卫随访模块',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 162,
          name: '公卫数据维护模块',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 163,
          name: '统计分析模块',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 164,
          name: '公卫健康档案管理',
          href: '',
          permission: '',
          sort: '',
          children: [{
            id: 1641,
            name: '新增',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 1642,
            name: '同步结果',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 1643,
            name: '上传省平台',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 1644,
            name: '上传随访信息',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 1645,
            name: '同步心电图',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 1646,
            name: '体检数据导出',
            href: '',
            permission: '',
            sort: ''
          }, {
            id: 1647,
            name: '上传异常结果导出',
            href: '',
            permission: '',
            sort: ''
          }]
        }, {
          id: 165,
          name: '公卫妇幼保健管理',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 166,
          name: '公卫医防融合',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 167,
          name: '核酸检测平台',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 168,
          name: '公卫体检分析',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 169,
          name: '第三方模块',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 1691,
          name: '健康体检',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 1692,
          name: '智慧公卫后台管理',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 17,
        name: '医疗机构',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 171,
          name: '县机构',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 172,
          name: '镇机构',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 173,
          name: '乡机构',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 18,
        name: '机构字典',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 181,
          name: '机构字典-省',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 182,
          name: '机构字典-市',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 183,
          name: '机构字典-县',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 184,
          name: '机构字典-乡镇',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 185,
          name: '机构字典-村',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 19,
        name: '核酸检测字典',
        href: '',
        permission: '',
        sort: ''
      }, {
        id: 20,
        name: '医防融合管理',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 2001,
          name: '设备管理',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 21,
        name: '智慧大屏',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 211,
          name: '智慧大屏体检地图',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 22,
        name: '智慧慢病医防融合',
        href: '',
        permission: '',
        sort: '',
        children: []
      }, {
        id: 23,
        name: '服务日志',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 231,
          name: '微信模板消息日志',
          href: '',
          permission: '',
          sort: ''
        }]
      }, {
        id: 24,
        name: '核心字典',
        href: '',
        permission: '',
        sort: '',
        children: [{
          id: 241,
          name: '核心配置',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 242,
          name: '微信模板消息',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 243,
          name: '售后服务',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 244,
          name: '项目字典',
          href: '',
          permission: '',
          sort: ''
        }, {
          id: 245,
          name: '药品目录',
          href: '',
          permission: '',
          sort: ''
        }]
      }],
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 100
      }
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    organs () {
      if (this.loginInfo.jgalllist === null && this.loginInfo.jgalllist.length > 0) return []
      return this.loginInfo.jgalllist
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  box-sizing: border-box;

  .children-head {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .children-head-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 14px;
      box-sizing: border-box;

      .children-head-left {
        display: flex;
        align-items: center;
        width: 33%;

        .children-head-open {
          width: 23px;
          height: 6px;
          border-top: 2px solid #595959;
          border-bottom: 2px solid #595959;
        }

        .el-input {
          margin-left: 50px;
        }

        /deep/.el-input__inner {
          border-color: #fff;
          font-size: 18px;
        }
      }

      .children-head-center {
        display: flex;
        align-items: center;
        flex: 1;
        color: #595959;
        font-size: 20px;

        /deep/.el-input__inner {
          font-size: 18px;
        }
      }

      .children-head-right {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex: 1;

        .success {
          color: #67C23A;
          font-size: 39px;
        }

        .lock {
          color: #595959;
          font-size: 30px;
        }

        .rank {
          font-size: 40px;
          transform: rotate(45deg);
        }

        .div {
          width: 41px;
          height: 40px;
          padding: 6px 7px;
          border: 1px solid #E6E6E6;
          border-radius: 6px;
          box-sizing: border-box;

          .div1 {
            width: 100%;
            height: 100%;
            padding-top: 3px;
            border: 1px solid #999999;
            box-sizing: border-box;

            .blue {
              width: 100%;
              height: 100%;
              background-color: #409EFF;
            }
          }
        }

        /deep/.el-input__inner {
          text-align: right;
          border: none;
          font-size: 18px;
        }
      }
    }
  }

  .children-box {
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 20px;
    background-color: #fff;
    box-sizing: border-box;

    /deep/.el-menu-item {
      font-size: 16px;
    }

    .tab-box {
      width: 100%;
      height: calc(100% - 80px);
      margin-top: 10px;

      .add {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 60px;
      }

      .table {
        width: 100%;
        height: calc(100% - 40px);

        /deep/.el-table .cell {
          text-align: center;
          color: #000;
          font-size: 16px;
        }

        /deep/ .el-table__row .el-table__cell:first-child .cell{
          text-align: left;
        }

        .flex {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          color: #399BFB;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;
      }
    }
  }

}

.children-page1 {
  position: relative;
}
</style>
