<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box" :class="{ 'title-active': tabIndex === 0 }" @click="tabClick(0)">机构列表</span>
      <span class="title-box" :class="{ 'title-active': tabIndex === 1 }" @click="tabClick(1)">下属机构</span>
    </div>
    <div class="son-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tabIndex: 0
    }
  },
  created () {},
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      const urls = ['/organrun/organlist/list', '/organrun/organlist/nextorgan']
      this.tabIndex = index
      this.$router.push(urls[index])
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      margin-right: 10px;
      padding: 0 9px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .title-active {
      background-color: #04B4D0;
    }

  }

  .son-content {
    width: 100%;
    height: calc(100% - 60px);
  }
}
</style>
