<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span class="title">健康讲座</span>
    </div>
    <div class="content">
      <div class="intervene">
        <div class="info-title">
          <div class="info-box">
            <div class="left">
              <el-input class="el-input-width" placeholder="请输入医生名称" v-model="theme"></el-input>
              <el-button size="small" class="blue" @click="getList">搜索</el-button>
              <el-button size="small" class="gray" @click="cleanTitle">清空</el-button>
            </div>
            <div class="right" @click="openAdd">
              <span class="el-icon-plus"></span>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="100%" :header-cell-style="{ background: '#024276' }">
            <el-table-column type="selection" width="42"></el-table-column>
            <el-table-column prop="theme" label="主题"></el-table-column>
            <el-table-column prop="hdsj" label="时间"></el-table-column>
            <el-table-column prop="hddd" label="地点"></el-table-column>
            <el-table-column prop="zcr" label="主持人"></el-table-column>
            <el-table-column label="图片">
              <template slot-scope="scope">
                <div class="block">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.imageUrl"></el-image>
              </div>
              </template>
            </el-table-column>
            <el-table-column prop="createtime" label="录入时间"></el-table-column>
            <el-table-column prop="unioid" label="小程序unioid"></el-table-column>
            <el-table-column prop="wxopenid" label="小程序id"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status === '1'">启用</span>
                <span v-else-if="scope.row.status === '2'">未启用</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <div class="flex">
                  <span @click="openUpdate(scope.row)">编辑</span>
                  <span @click="openDelete(scope.row)">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增医生 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 编辑医生 -->
    <Update v-if="showUpdate" :selectInfo="selectInfo"  @close="closeUpdate"></Update>
    <!-- 删除 -->
    <Delete v-if="showDelete" :selectInfo="selectInfo"  @close="closeDelete"></Delete>
    <!-- 导入 -->
    <Upload v-if="showUpload" @close="closeUpload"></Upload>
  </div>
</template>
<script>
import Add from './add.vue'
import Update from './update.vue'
import Delete from './delete.vue'
export default {
  components: {
    Add,
    Update,
    Delete
  },
  data () {
    return {
      tableData: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        jgbh: ''
      },
      selectInfo: {},
      value: '',
      showAdd: false,
      id: '',
      wxopenid: '',
      imageurl: '',
      mpopenid: '',
      status: '',
      unioid: '',
      zcr: '',
      hddd: '',
      hdsj: '',
      theme: '',
      showUpdate: false,
      showDelete: false,
      showUpload: false
    }
  },
  created () {
    this.params.jgbh = this.$store.state.loginInfo.value || ''
    this.getList()
  },
  methods: {
    async getList () {
      try {
        this.params.start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        this.params.length = this.query1.pageSize + ''
        const { data: res } = await this.$http.get('/api/jkct/jkjyjzjl', { params: this.params })
        console.log('活动列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal || 0
        // this.$nextTick(() => {
        //   this.$refs.table.bodyWrapper.scrollTop = 0
        // })
      } catch (error) {
        console.log('活动列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    cleanTitle () {
      this.theme = ''
      this.getList()
    },
    change (e, item) {
      console.log(e, item)
      this.update(item)
    },
    async update (item) {
      try {
        const obj = JSON.parse(JSON.stringify(item))
        if (item.qyzt) {
          obj.qyzt = '1'
        } else {
          obj.qyzt = '0'
        }
        const res = await this.$http.put('/ykdyyglypgls', obj)
        console.log('活动列表修改', res)
        if (res.status !== 200) return this.$message.error(res.msg || res.message)
        this.$message.success('修改成功')
      } catch (error) {
        console.log('活动列表修改请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
      this.getList()
    },
    openUpdate (info) {
      console.log('update', info)
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.id = ''
      this.showUpdate = false
    },
    openDelete (info) {
      this.selectInfo = info
      this.showDelete = true
    },
    closeDelete (flan) {
      if (flan) {
        this.getList()
      }
      this.id = ''
      this.showDelete = false
    },
    openUpload () {
      this.showUpload = true
    },
    closeUpload () {
      this.showUpload = false
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 20px;
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    span {
      height: 38px;
      line-height: 38px;
      margin-right: 16px;
      padding: 0 12px;
      text-align: center;
      color: #fff;
      font-size: 22px;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 20px;

    .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-right: 10px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .table {
        width: 99%;
        height: calc(100% - 150px);
        margin-top: 10px;
        border: 4px #073F73 solid;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0px; // 纵向滚动条的宽度
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: 17px;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: 16px;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: 2px solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: 1px solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: 1px solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: 1px solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0px;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 16px;

        span {
          color: #0077F9;
          cursor: pointer;
          letter-spacing: 0;
        }

        .red {
          color: #FF0000;
        }

        //按钮文字显示
        .el-switch {
          width: 70px !important;
          height: 25px !important;
        }

        ::v-deep .el-switch__core {
          width: 100% !important;
          height: 24px;
          border-radius: 100px;
          border: none;
        }

        ::v-deep .el-switch__core::after {
          width: 20px;
          height: 20px;
          top: 2px;
        }

        ::v-deep .el-switch.is-checked .el-switch__core::after {
          margin-left: -21px;
        }

        /*关闭时文字位置设置*/
        ::v-deep .el-switch__label--right {
          position: absolute;
          z-index: 1;
          right: 6px;
          margin-left: 0px;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: 12px;
          }
        }

        /* 激活时另一个文字消失 */
        ::v-deep .el-switch__label.is-active {
          display: none;
        }

        /*开启时文字位置设置*/
        ::v-deep .el-switch__label--left {
          position: absolute;
          z-index: 1;
          left: 5px;
          margin-right: 0px;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: 12px;
          }
        }

      }

      .flex1 {
        justify-content: center;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }
}
</style>
