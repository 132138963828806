<template>
  <div class="addWiki">
    <div class="box">
      <div class="item">
        <span>老年人</span>
        <div class="group">
          <span class="group-item">高血压</span>
          <span class="group-item">糖尿病</span>
          <span class="group-item">冠心病</span>
          <span class="group-item">心肌梗死</span>
          <span class="group-item">耳聋</span>
          <span class="group-item">心绞痛</span>
          <span class="group-item">白内障</span>
          <span class="group-item">通风</span>
          <span></span>
        </div>
      </div>
      <div class="item">
        <span>儿童</span>
        <div class="group">
          <span class="group-item">百日咳</span>
          <span class="group-item">近视</span>
          <span class="group-item">性早熟</span>
          <span class="group-item">龋齿</span>
          <span class="group-item">水痘</span>
          <span class="group-item">营养不良</span>
          <span class="group-item">手足口病</span>
          <span class="group-item">小儿感冒</span>
          <span></span>
        </div>
      </div>
      <div class="item">
        <span>孕产妇</span>
        <div class="group">
          <span class="group-item">破水</span>
          <span class="group-item">难产</span>
          <span class="group-item">产褥感染</span>
          <span class="group-item">附件炎</span>
          <span class="group-item">痔疮</span>
          <span class="group-item">早孕反应</span>
          <span class="group-item group-item1">急性宫颈炎</span>
          <span class="group-item group-item2">阿米巴性阴道炎</span>
          <span class="span"></span>
        </div>
      </div>
      <div class="item">
        <span>亚健康</span>
        <div class="group">
          <span class="group-item">颈椎病</span>
          <span class="group-item">脱发</span>
          <span class="group-item">失眠</span>
          <span class="group-item">便秘</span>
          <span class="group-item">自闭症</span>
          <span class="group-item">多梦</span>
          <span class="group-item group-item1">记忆障碍</span>
          <span class="group-item group-item1">考后综合征</span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="title">
      <span>新增</span>
    </div>
    <div class="line">
      <div class="td">
        <span>疾病名称</span>
      </div>
      <div class="td td1 td2">
        <el-input class="width"></el-input>
      </div>
      <div class="td td1">
        <span class="margin">常见病类型</span>
        <el-input class="width1"></el-input>
      </div>
    </div>
    <div class="line">
      <div class="td">
        <span>关键字</span>
      </div>
      <div class="td td1">
        <el-input></el-input>
      </div>
    </div>
    <div class="line line1">
      <span class="item" :class="{ item1: index === 0 }" v-for="(item, index) in tabs" :key="index">{{ item }}</span>
    </div>
    <div class="line line2">
      <div class="td td3">
        <span>内容</span>
      </div>
      <div class="td td1 td3">
        <el-input type="textarea" ></el-input>
      </div>
    </div>
    <div class="line">
      <div class="td">
        <span>附件</span>
      </div>
      <div class="td td1 td4">
        <el-input class="width"></el-input>
        <el-button size="small" type="primary">上传</el-button>
      </div>
    </div>
    <div class="submit">
      <el-button @click="close">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabs: Object.freeze(['概述', '病因', '症状', '饮食', '预防', '检查', '治疗', '诊断', '并发症'])
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.addWiki {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: #08223D;

  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 2.25rem;
    margin-top: .25rem;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 25%;
      height: 2.075rem;
      color: #FFFFFF;
      font-size: .225rem;
      cursor: pointer;

      .group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 4.325rem;
        height: 1.6rem;
        box-sizing: border-box;

        span {
          width: 1.125rem;
          height: .375rem;
          margin-bottom: .125rem;
        }

        .span {
          width: .625rem;
        }

        .group-item {
          line-height: .375rem;
          text-align: center;
          background-image: url('../../../img/ssjk/capsule.png');
          background-size: 100% 100%;
          color: #fff;
          font-size: .225rem;
          white-space: nowrap;
        }

        .group-item1 {
          width: 1.5rem;
        }

        .group-item2 {
          width: 2rem;
        }
      }
    }
  }

  .title {
    height: .5rem;
    line-height: .5rem;
    padding-left: .275rem;
    color: #01EEFD;
    font-size: .275rem;
  }

  .line {
    display: flex;
    align-items: center;
    height: .75rem;
    border: .0125rem solid #01EEFD;
    border-bottom: none;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: .225rem;

    .td {
      width: 1.35rem;
      height: .75rem;
      line-height: .75rem;
      text-align: center;
      border: .0125rem solid #01EEFD;
      border-top: none;
      border-left: none;
      padding: 0 .125rem;
      box-sizing: border-box;
    }

    .td1 {
      flex: 1;

      .width {
        width: 4.15rem;
      }

      .width1 {
        width: 2.025rem;
      }

      .margin {
        margin-right: .125rem;
      }
    }

    .td2 {
      display: flex;
    }

    .td3 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.8375rem;
    }

    .td4 {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .el-button {
        width: 1.625rem;
        height: .375rem;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: .225rem;
      }
    }

    .item {
      width: 1.625rem;
      height: .375rem;
      line-height: .375rem;
      margin-right: .5rem;
      text-align: center;
      color: #fff;
      font-size: .225rem;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      cursor: pointer;
    }

    .item1 {
      background-color: #FFA8A8;
    }
  }

  .line1 {
    padding-left: .25rem;
    box-sizing: border-box;
  }

  .line2 {
    height: 2.8375rem;

    /deep/.el-textarea__inner {
      width: 100%;
      height: 2.825rem;
      background-color: transparent;
      border: none;
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    width: 100%;
    height: .45rem;
    margin-top: .25rem;

    .el-button {
      width: 1.625rem;
      height: .45rem;
      line-height: .45rem;
      text-align: center;
      padding: 0;
      color: #fff;
      font-size: .225rem;
      border: none;
      border-radius: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    }
  }
}
</style>
