<template>
  <div class="son">
    <div class="son-title">
      <span class="title-box">小程序维护</span>
      <div class="info-box">
            <div class="right" @click="openAdd">
              <span class="el-icon-plus"></span>
            </div>
      </div>
    </div>

    <!-- <div class="son-search">
      <div class="son-item">
        <span class="key">机构名称</span>
        <el-input placeholder="请输入"></el-input>
      </div>
      <el-button>搜索</el-button>
      <el-button>清空</el-button>
    </div> -->
    <el-table :data="tableData" height="400" style="width: 100%">
      <el-table-column prop="id" label="序号">
      </el-table-column>
      <el-table-column prop="mchid" label="支付商户号">
      </el-table-column>
      <el-table-column prop="mchkey" label="支付商户key">
      </el-table-column>
      <el-table-column prop="ssjgbh" label="所属机构编号">
      </el-table-column>
      <el-table-column prop="ssjgmc" label="所属机构名称">
      </el-table-column>
      <el-table-column prop="czybm" label="操作员编码">
      </el-table-column>
      <el-table-column prop="czymc" label="操作员名称">
      </el-table-column>
      <el-table-column prop="pttype" label="平台类型">
      </el-table-column>
      <el-table-column prop="wxptname" label="平台名称">
      </el-table-column>
      <el-table-column prop="flag" label="状态">
      </el-table-column>
      <el-table-column prop="token" label="token">
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <!-- slot-scope="scope" -->
          <div class="flex">
            <span @click="openUpdate(scope.row)">编辑</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNum"
      background
      :page-size="query.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="query.total"></el-pagination>
    <Add v-if="showAdd"></Add>
    <Update v-if="showUpdate" :selectInfo="selectInfo"></Update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import Update from './update.vue'
export default {
  components: {
    Add,
    Update
  },
  data () {
    return {
      issynchronous: false,
      options: [],
      value: '',
      tableData: [],
      showSynchronous: false,
      showUpdate: false,
      showAdd: false,
      selectInfo: {},
      params: {
        start: '',
        length: '',
        jgbh: ''

      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      console.log(this.params)
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/ykdwxpzinfos', { params: this.params })
        console.log('小程序列表', res)
        if (res.code !== 0 || res.data === null || res.data.length < 1) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('小程序列表', err)
      }
    },
    openUpdate (info) {
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openAdd () {
      this.showAdd = true
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query.pageNum = val
      this.getInfo()
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;

  .son-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .title-box {
      height: 38px;
      line-height: 38px;
      margin-right: 10px;
      padding: 0 9px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .title-active {
      background-color: #04B4D0;
    }

  }
  .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-right: 10px;
          }

          .date {
            margin-right: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }

  .son-search {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-right: 30px;

      .key {
        width: 110px;
        color: #01EEFD;
        font-size: 22px;
      }

      .el-input {
        width: 180px;
      }
    }

    .el-button {
      margin-top: 20px;
      margin-right: 40px;
      background-color: #04B4D0;
      border: none;
      color: #fff;
    }
  }

  .add {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;

    .icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #04B4D0;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .el-table {
    background-color: #081C38;
    border: 4px solid #073F73;
    box-sizing: border-box;

    &::before {
      background-color: transparent;
    }

    // 表头首行
    /deep/th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    /deep/td.el-table__cell {
      border-bottom: 1px solid #01EEFD;
    }

    // 表头背景颜色
    /deep/th.el-table__cell {
      background-color: #024276;
    }

    /deep/.el-table__cell {
      text-align: center;
      color: #fff;
    }

    /deep/tr {
      background-color: #081C38;
    }

    /deep/.el-table__body tr:hover>td.el-table__cell {
      background-color: transparent !important;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.is-indeterminate .el-checkbox__inner,
    /deep/.is-checked .el-checkbox__inner {
      background-color: #04B4D0;
    }

    /deep/.el-table__body-wrapper::-webkit-scrollbar {
      width: 0;
    }

    /deep/.el-table__empty-text {
      color: #fff;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      color: #fff;

      span {
        cursor: pointer;
      }

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .green {
    color: #00FF88;
  }

  .organ {
    color: #FF6600;
  }

  .red {
    color: #FF0000;
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    /deep/.el-pagination__total,
    /deep/.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    /deep/.el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
